<template>
  <div
    @click="$emit('click')"
    class="relative"
  >
    <div class="mb-2">
      <label
        class="font-medium"
        v-if="label"
        :for="inputId"
      >
        {{ label }}
      </label>

      <span
        v-if="subLabel"
        class="block text-bb-text-secondary text-sm font-regular"
      >
        {{ subLabel }}
      </span>
    </div>

    <div class="relative">
      <div class="url-prefix flex items-center justify-center text-sm font-normal">
        <p class="flex items-center justify-center text-sm font-normal">URL</p>
      </div>
    </div>
    <input
      :type="inputType"
      :id="inputId"
      :name="inputName"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="e => $emit('input', e.target.value)"
      @keydown="emitKeyDown"
      @focus="handleInputFocus"
      @focusout="handleInputFocus"
      autocomplete="off"
      :class="inputWrapperClasses"
      class="url-input"
    />

    <feedback-message
      v-if="!hideFeedback"
      :message="errorMessage"
      class="error-message"
    />
  </div>
</template>

<script>
import FeedbackMessage from '@/components/base/FeedbackMessage'

export default {
  name: 'url-input',
  components: { FeedbackMessage },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input', 'focus', 'focusout'],
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    inputWrapperClasses() {
      if (this.disabled) {
        return { disabled: true }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error || !!this.errorMessage,
      }
    },
  },
  methods: {
    handleInputFocus(e) {
      this.hasFocus = e.type === 'focus'
      this.$emit(e.type, e)
    },
    emitKeyDown(e) {
      this.$emit('keydown', e)
    },
  },
}
</script>

<style scoped lang="scss">
.url-input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 8px;
  background: white;
  border: $neutral-100 solid 1px;
  border-radius: 8px;
  color: $bb-text-secondary !important;
  padding-left: 65px;
}

.url-prefix {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 52px;
  height: 38px;
  cursor: pointer;
  background: $neutral-50;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-right: $neutral-100 solid 1px;
}

.focus {
  outline: $bb-brand-purple solid 2px;
  color: $bb-text-default !important;
}

.error {
  border: $bb-error solid 1px;
  &:focus {
    border: $neutral-100 solid 1px;
    outline: $bb-error solid 2px;
  }
}

.error-message {
  position: absolute;
  bottom: -23px;
}
</style>
