<template>
  <div class="mb-20">
    <div>
      <div class="flex flex-row">
        <img
          src="/content/images/illustrations/site-and-business.png"
          alt="site-and-business"
          class="mr-8 mt-0 mb-auto"
        />
        <p class="py-8 max-w-2xl my-auto">
          For better results and improved campaign performance, complete the
          <span class="font-bold"> Offerings</span> with a detailed description of the products or services offered by
          the site.
          <br />
          Note: Fields below have to be submitted in the Main Language selected.
        </p>
      </div>
      <div v-if="loading || !site">
        <BrightbidLoader />
      </div>
      <div
        v-else
        class="py-10 px-6 max-w-150 space-y-10"
      >
        <div
          v-if="isSaving"
          class="fixed inset-0 bg-white rounded-lg z-50 bg-opacity-75 transition-opacity"
        >
          <div class="h-full flex items-center justify-center">
            <brightbid-loader size="120" />
          </div>
        </div>

        <div class="space-y-3">
          <h3 class="h4">Extra Info: Help the AI</h3>

          <TextArea
            v-model="extraInfo"
            input-name="help-the-ai"
            input-id="help-the-ai"
            sub-label="Our models still have a lot to learn! Help our AI with extra information it should keep in mind when working with your account"
            :min-length="inputLengths.extraInfo.min"
            :max-length="inputLengths.extraInfo.max"
            :error-message="extraInfoErrorMessage"
            :rows="5"
            @focusout="$v.extraInfo.$touch"
          >
            <template #label>
              <div class="flex items-center pb-2 gap-2">
                <ic-chat
                  class="text-bb-brand-purple"
                  size="20"
                />
                <p class="font-medium">Extra info: Help the AI</p>
              </div>
            </template>
          </TextArea>

          <div class="flex justify-end">
            <merge-button-round
              button-type="primary"
              @click="saveExtraInfo"
            >
              <p class="px-3">Save</p>
            </merge-button-round>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-col gap-2">
            <p class="h4">Offerings</p>
            <p class="text-bb-text-secondary p1">
              The products/services offered on this site. <br />
              This information is used with the <span class="font-bold"> Google Ads Channel.</span>
            </p>
          </div>
          <div
            class="text-bb-brand-purple flex flex-row gap-2 my-auto cursor-pointer select-none hover:opacity-75"
            @click="addOffering"
          >
            <ic-plus />
            <p>Add Offering</p>
          </div>
        </div>
        <div
          v-for="offering in offerings"
          :key="offering.id"
          class="p-6 mt-6 border border-neutral-100 rounded-lg"
        >
          <div class="flex flex-col gap-4 w-full h-full">
            <div class="flex flex-row justify-between">
              <p class="h5">{{ offering.name }}</p>
              <div class="flex flex-row gap-2 text-bb-disabled-gray">
                <ic-pencil
                  class="cursor-pointer hover:text-bb-text-secondary"
                  @click="editOffering(offering)"
                />
                <ic-delete
                  class="cursor-pointer hover:text-bb-text-secondary"
                  @click="deleteOffering(offering)"
                />
              </div>
            </div>
            <p>
              {{ offering.description }}
            </p>
            <div>
              <div class="flex flex-row gap-2 items-center">
                <ic-chain class="text-bb-text-secondary" />
                <p class="font-bold">Linked Ad Groups</p>
              </div>
              <div class="flex flex-col gap-2 mt-2">
                <div
                  v-for="adgroup in offering.adgroups"
                  :key="adgroup.id"
                >
                  <p>{{ adgroup.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bb-base-modal
      v-if="showOfferingModal"
      width="600px"
      height="100%"
      @close="closeModal"
    >
      <template #container>
        <offerings-form
          :site-id="site.id"
          :offering-id="selectedOffering"
          :is-modal-loading="isModalLoading"
          @close="closeModal"
          @save="saveOffering"
        ></offerings-form>
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import IcPlus from 'vue-material-design-icons/Plus'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'
import OfferingsForm from '@/components/modals/forms/OfferingsForm'
import IcDelete from 'vue-material-design-icons/DeleteOutline'
import IcPencil from 'vue-material-design-icons/PencilOutline'
import Toast from '@/components/shared/Toast'
import IcChain from 'vue-material-design-icons/Link'
import TextArea from '@/components/input/brightbid/TextArea.vue'
import IcChat from '@/components/icon/brightbid/ic-chat.vue'

export default {
  name: 'FeedTheAI',
  components: {
    IcChat,
    BrightbidLoader,
    IcPlus,
    BbBaseModal,
    OfferingsForm,
    IcDelete,
    IcPencil,
    IcChain,
    TextArea,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      loading: false,
      isModalLoading: false,
      showOfferingModal: false,
      selectedOffering: null,
      extraInfo: '',
      inputLengths: {
        extraInfo: {
          max: 300,
        },
      },
    }
  },
  computed: {
    ...mapGetters('offerings', ['offerings']),
    extraInfoErrorMessage() {
      const lengths = this.inputLengths.extraInfo
      if (!this.$v.extraInfo.$dirty) return null
      if (!this.$v.extraInfo.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
  },
  validations() {
    return {
      extraInfo: {
        maxLength: maxLength(this.inputLengths.extraInfo.max),
      },
    }
  },
  async created() {
    this.extraInfo = this.site.extra_info || ''

    this.loading = true
    await this.fetchOfferings(this.site.id)
      .then(() => {
        this.loading = false
      })
      .catch(error => {
        console.error('Failed to fetch offerings:', error)
        this.loading = false
      })
  },
  methods: {
    ...mapActions('offerings', ['fetchOfferings']),
    addOffering() {
      this.showOfferingModal = true
    },
    editOffering(offering) {
      this.selectedOffering = offering.id
      this.showOfferingModal = true
    },
    async deleteOffering(offering) {
      try {
        await this.$http.delete(`/common/site/${this.site.id}/offering/${offering.id}`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Offering deleted successfully.',
            type: 'success',
          },
        })
        await this.fetchOfferings(this.site.id)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Offering could not be deleted. Please try again.',
            type: 'error',
          },
        })
      }
    },
    async saveOffering(offering) {
      this.isModalLoading = true
      try {
        offering.adgroups = offering.adgroups.map(adgroup => adgroup.value).join(', ')
        if (this.selectedOffering) {
          await this.$http.patch(`/common/site/${this.site.id}/offering/${offering.id}`, offering)
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Offering saved.',
              type: 'success',
            },
          })
        } else {
          await this.$http.post(`/common/site/${this.site.id}/offering`, offering)
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Offering added.',
              type: 'success',
            },
          })
        }
        await this.fetchOfferings(this.site.id)
        this.showOfferingModal = false
        this.selectedOffering = null
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Offering could not be saved. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.isModalLoading = false
      }
    },
    closeModal() {
      this.showOfferingModal = false
      this.selectedOffering = null
    },

    async saveExtraInfo() {
      this.$v.extraInfo.$touch()
      if (this.$v.extraInfo.$invalid) return

      try {
        this.isSaving = true
        await this.$http.patch(`/common/site/${this.site.id}/business-extra`, {
          description: this.site.description,
          extra_info: this.extraInfo,
        })

        this.$emit('site-updated')

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Extra info saved successfully.',
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to save extra info. Please try again later.',
            type: 'error',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
  },
}
</script>
