<template>
  <div @click="$emit('click')">
    <div class="mb-2 flex flex-col">
      <div class="flex flex-row gap-1 -mb-2">
        <slot name="label">
          <label
            v-if="label"
            class="font-medium"
            :for="inputId"
          >
            {{ label }}
          </label>
        </slot>
        <new-tooltip
          v-if="tooltip"
          direction="top-start"
          :theme="tooltipTheme"
        >
          <template #content>
            <slot name="tooltip-content" />
          </template>
        </new-tooltip>
      </div>
      <span
        v-if="subLabel"
        class="block text-bb-text-secondary text-sm font-regular"
      >
        {{ subLabel }}
      </span>
    </div>

    <textarea
      :id="inputId"
      :name="inputName"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      autocomplete="off"
      :rows="rows"
      :class="[inputWrapperClasses, { 'resize-none': disableResize }]"
      class="text-input"
      @input="e => $emit('input', e.target.value)"
      @keydown="emitKeyDown"
      @focus="handleInputFocus"
      @focusout="handleInputFocus"
      @blur="blurHandler"
    />

    <div class="flex justify-between items-center">
      <feedback-message
        v-if="!hideFeedback"
        :message="errorMessage"
      />
      <p
        class="text-xs text-bb-text-secondary"
        :class="lengthValidation ? 'text-bb-error' : ''"
      >
        {{ valueLength }}/{{ maxLength }}
      </p>
    </div>
  </div>
</template>

<script>
import FeedbackMessage from '@/components/base/FeedbackMessage'
import NewTooltip from '@/components/alert/NewTooltip'

export default {
  name: 'TextArea',
  components: { FeedbackMessage, NewTooltip },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    tooltipTheme: {
      type: String,
      default: 'sm',
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 3,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    minLength: {
      type: Number,
      default: 0,
    },
    disableResize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'focus', 'focusout'],
  data() {
    return {
      hasFocus: false,
      lengthValidation: false,
    }
  },
  computed: {
    inputWrapperClasses() {
      if (this.disabled) {
        return { disabled: true }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error || !!this.errorMessage,
      }
    },
    valueLength() {
      if (!this.value) return 0

      return this.value.length
    },
  },
  methods: {
    handleInputFocus(e) {
      this.hasFocus = e.type === 'focus'
      this.$emit(e.type, e)
    },
    blurHandler() {
      switch (true) {
        // validate min and max lengths
        case this.minLength !== 0 && this.maxLength !== 0:
          this.lengthValidation = this.valueLength > this.maxLength || this.valueLength < this.minLength
          break
        // validate max length
        case this.maxLength !== 0 && this.minLength === 0:
          this.lengthValidation = this.valueLength > this.maxLength
          break
        // no validation
        default:
          this.lengthValidation = false
          break
      }
    },
    emitKeyDown(e) {
      this.$emit('keydown', e)
    },
  },
}
</script>

<style scoped lang="scss">
.text-input {
  width: 100%;
  font-size: 14px;
  padding: 8px;
  background: white;
  border: $neutral-100 solid 1px;
  border-radius: 8px;
  color: $bb-text-secondary !important;
}

.focus {
  outline: $bb-brand-purple solid 2px;
  color: $bb-text-default !important;
}

.error {
  border: $bb-error solid 1px;
  &:focus {
    border: $neutral-100 solid 1px;
    outline: $bb-error solid 2px;
  }
}
</style>
