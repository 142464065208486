<template>
  <div class="mb-20">
    <div>
      <div class="md:flex flex-row">
        <img
          src="/content/images/illustrations/site-details.png"
          alt="site-details"
          class="mr-8 mt-0 mb-auto"
        />
        <p class="py-8 max-w-2xl my-auto">
          Provide crucial information about your website, including the main language, site name, site URL, and target
          country. Ensure that the fields below are filled out in the selected Main Language.
        </p>
      </div>
      <div v-if="pageLoading || !site">
        <BrightbidLoader />
      </div>
      <div v-else>
        <form
          class="mt-10 max-w-2xl"
          @keydown.prevent.enter="submit"
        >
          <div class="space-y-6">
            <div>
              <p class="h4">General Site info</p>
              <p class="text-bb-text-secondary p1">
                Configure essential details. In this section, input critical information about your website: main
                language, site name, site URL and target country.
              </p>
            </div>
            <text-input
              v-model="siteForm.name"
              :disabled="isLoading"
              class="w-full"
              label="Site Name"
              sub-label="The name of the site."
              placeholder="Site Name"
              input-type="text"
              input-name="name"
              input-id="name"
              :error-message="$v.siteForm.name.$error ? 'Please enter a site name' : null"
              @focusout="$v.siteForm.name.$touch"
            />
            <url-input
              v-model="siteForm.url"
              :disabled="isLoading"
              class="w-full"
              label="Site Link"
              sub-label="The link leading to the website where the sale is made."
              input-type="text"
              input-name="url"
              input-id="url"
              placeholder="Website"
              :error-message="$v.siteForm.url.$error ? 'Please enter a URL' : null"
              @focusout="$v.siteForm.url.$touch"
            />
            <search-input
              v-model="siteForm.country"
              input-id="country"
              input-name="country"
              label="Country"
              placeholder="Select Country"
              sub-label="The country the site operates in."
              :options="countryOptions"
              :error-message="$v.siteForm.country_code.$error ? 'Please enter a site country' : null"
              @select-item="onCountryChange"
              @focusout="$v.siteForm.country_code.$touch"
            />
            <search-input
              ref="languageInput"
              v-model="siteForm.language"
              input-id="language"
              input-name="language"
              label="Main Language"
              sub-label="The main language the site operates in."
              placeholder="Select Language"
              :options="languageOptions"
              :error-message="$v.siteForm.language_code.$error ? 'Please enter a language' : null"
              @select-item="updateLanguage"
              @focusout="$v.siteForm.language_code.$touch"
            />
            <url-input
              v-if="!standardUser"
              v-model="siteForm.embed_url"
              :disabled="isLoading"
              class="w-full"
              label="Embed Report"
              sub-label="The iframe URL of the report."
              input-type="text"
              input-name="report"
              input-id="report"
              placeholder="https://lookerstudio.google.com/embed/reporting/123"
              :error-message="
                $v.siteForm.embed_url.$error
                  ? 'The iframe URL was not added correctly, please check that it’s correct before saving'
                  : null
              "
              @focusout="$v.siteForm.embed_url.$touch"
            />
            <div v-if="!standardUser">
              <p class="font-medium py-1">Managed Campaigns</p>
              <div class="flex flex-row gap-2 items-start">
                <check-box
                  v-model="siteManagedCampaignsIdentifier"
                  input-name="site-managed-campaign-identifier"
                  input-id="site-managed-campaign-identifier"
                  class="mt-1"
                  :disabled="isLoading"
                />
                <p>
                  When this box is checked, only the campaigns whose name contains “BrightBid” are considered managed.
                  When this box is unchecked, all campaigns are considered managed.
                </p>
              </div>
            </div>
            <div class="flex flex-row-reverse gap-4 pt-8">
              <merge-button-round
                :disabled="isLoading"
                button-type="primary"
                class="w-24"
                @click="submitForm"
              >
                <p>Save</p>
              </merge-button-round>
              <merge-button-round
                :disabled="isLoading"
                button-type="secondary"
                class="w-24"
                @click="populateForm"
              >
                <p>Cancel</p>
              </merge-button-round>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, url, helpers } from 'vuelidate/lib/validators'
import TextInput from '@/components/input/brightbid/TextInput'
import UrlInput from '@/components/input/brightbid/URLInput'
import { mapActions, mapState, mapGetters } from 'vuex'
import Toast from '@/components/shared/Toast'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import CheckBox from '@/components/input/brightbid/CheckBox'

const urlValidator = helpers.regex('urlValidator', /^https:\/\/lookerstudio\.google\.com\/embed\/reporting\/.+$/i)

export default {
  name: 'SettingsForm',
  components: {
    TextInput,
    UrlInput,
    BrightbidLoader,
    SearchInput,
    CheckBox,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageLoading: false,
      isLoading: false,
      siteForm: {
        name: null,
        url: null,
        country_code: null,
        language_code: null,
        type: null,
        organization_id: null,
        country: null,
        language: null,
        embed_url: null,
      },
      siteManagedCampaignsIdentifier: null,
    }
  },
  computed: {
    ...mapState('country', {
      allCountries: 'countries',
    }),
    ...mapState('language', {
      allLanguages: 'languages',
    }),
    ...mapState('site', ['managedCampaignsIdentifier']),
    ...mapGetters('country', {
      user: 'auth/user',
      formattedAmazonMarkets: 'formattedAmazonMarkets',
    }),
    ...mapGetters({
      user: 'auth/user',
    }),
    countryOptions() {
      if (this.site && this.site.type === 'amazon') {
        return this.formattedAmazonMarkets
      }
      return this.allCountries
    },
    languageOptions() {
      if (this.site && this.site.type === 'amazon' && this.siteForm.country) {
        const selectedCountry = this.formattedAmazonMarkets.find(
          country => country.value === this.siteForm.country.value,
        )
        return selectedCountry ? selectedCountry.languages : []
      }
      return this.allLanguages
    },
    standardUser() {
      return this.user.role.name === 'standard'
    },
  },
  validations() {
    return {
      siteForm: {
        language_code: { required },
        name: { required },
        url: { required, url },
        country_code: { required },
        embed_url: { urlValidator },
      },
    }
  },
  watch: {
    site: {
      deep: true,
      handler() {
        this.populateForm()
      },
    },
  },
  async mounted() {
    this.pageLoading = true
    await Promise.all([
      this.fetchCountries(),
      this.fetchAmazonAvailableMarketsData(),
      this.fetchLanguages(),
      this.fetchSiteManagedCampaignsIdentifier(this.site.id),
    ])
    this.populateForm()
    this.pageLoading = false
  },
  methods: {
    ...mapActions('country', ['fetchCountries', 'fetchAmazonAvailableMarketsData']),
    ...mapActions('language', ['fetchLanguages']),
    ...mapActions('site', ['fetchSiteManagedCampaignsIdentifier']),
    populateForm() {
      if (!this.site) return

      this.siteForm.name = this.site.name
      this.siteForm.url = this.site.url
      this.siteForm.country_code = this.site.country_code
      this.siteForm.language_code = this.site.language_code
      this.siteForm.type = this.site.type
      this.siteForm.organization_id = this.site.organization_id
      this.siteForm.embed_url = this.site?.embed_url || null
      this.siteForm.country = this.countryOptions.find(country => country.value === this.site.country_code)
      this.siteForm.language = this.languageOptions.find(
        language => language.value.toLowerCase() === this.site.language_code,
      )
      this.siteManagedCampaignsIdentifier = this.managedCampaignsIdentifier
      this.$v.$reset()
    },
    onCountryChange(newCountry) {
      if (this.siteForm.country !== newCountry) {
        this.siteForm.language = null
        this.$refs.languageInput.selectedItem = null
        this.$refs.languageInput.searchInput = null
      }
      this.siteForm.country = newCountry
      this.siteForm.country_code = newCountry.value
      if (this.site.type !== 'amazon') return

      const selectedCountry = this.formattedAmazonMarkets.find(
        country => country.value.toLowerCase() === newCountry.value.toLowerCase(),
      )
      if (selectedCountry && selectedCountry.languages) {
        this.siteForm.language = selectedCountry.languages[0]
      }
    },
    updateLanguage(newLanguage) {
      this.siteForm.language = newLanguage
      this.siteForm.language_code = newLanguage.value.toLowerCase()
    },
    async submitForm() {
      this.$v.$touch()
      if (this.$v.siteForm.$invalid)
        return this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'One or more fields are not valid',
            type: 'error',
          },
        })
      try {
        this.isLoading = true

        if (!this.standardUser) {
          let payload = {
            identify_managed_campaigns_with_brightbid: this.siteManagedCampaignsIdentifier,
          }
          await this.$http.post(`/search/site/${this.site.id}/managed_campaign_identifier`, payload)
        }

        let payload = { ...this.siteForm, embed_url: this.siteForm.embed_url || null }
        await this.$http.patch(`/common/site/${this.site.id}/general`, payload)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Site has been updated!',
            type: 'success',
          },
        })
        this.isLoading = false
        await this.fetchSiteManagedCampaignsIdentifier(this.site.id)
        this.$emit('site-updated')
      } catch (e) {
        console.log(e)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Could not save changes',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
